.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  font-family: Georgia, serif;
}

.submitbtn{
  background: green;
    padding: 9px;
    border: 1px solid green;
    outline: 1px solid green;
    color: white;
    cursor: pointer;
}

.App-header {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chatblock{
  z-index: 1000;
  top: 11px;
  position: fixed;
  right: 27px;
  display: none;
}

.chat-logo{
    padding: 16px;
    border-radius: 50%;
    position: absolute;
    bottom: 8px;
    right: 12px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    background: #000033;
}

.chat-view{
  background: linear-gradient(92.71deg, #000033 -1.36%, #cccccc 200.39%);
  height: 180px;
  width: 358px;
  text-align: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
}

.hi-logo{
  width: 13%;
  border: 4px solid white;
  margin-top: 25px;
  border-radius: 50%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.chat-q{
  background: rgba(242, 242, 242, 1);
  padding: 30px 10px 3px 10px;
  height: 150px;
  padding-bottom: 78px;
}

.chat-q-list{
  background: rgba(255, 255, 255, 1);
  padding: 29px;
  border-radius: 10px;
}

.name-font{
  font-size: 17px;
}

.chat-header{
  color: rgba(24, 39, 58, 0.94);
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.faqs{
  color: #0E91EF;
  font-size: 16px;
  text-align: left;
  padding: 0px 5px;
  margin-bottom: 9px;
}

.faqs-list{
  list-style: none;
  text-align: left;
  padding: 0px;

}
.faqs:hover{
  background: #F0F7FF;
  border-radius: 10px;
  padding: 7px;
  cursor: pointer;
}
.chat-options-list{
  background: linear-gradient(0deg, #F2F2F2, #F2F2F2),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  color: #002AC0;
  display: flex;
  border: 1px solid #f3f3f3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.chat-options-list a{
  text-decoration: none;
  font-size: 15px;
}

.option-div{
  width: 50%;
  padding: 15px;
  background: #000033;
}
.option-div a{
  color: white;
}
.option-div a:hover{
  background: #000033;
  color: white;
  padding: 8px;
  border-radius: 15px;
}

.option-active{
  background: #000033;
  color: white;
  padding: 8px;
  border-radius: 15px;
}

.messageblock{
  border: 1px solid whitesmoke;
  z-index: 1000;
  top: 12px;
  position: fixed;
  right: 27px;
  height: 500px;
  width: 358px;
  border-radius: 10px;
  display: none;
}
.messageviewblock{
  border: 1px solid whitesmoke;
  z-index: 1000;
  top: 12px;
  position: fixed;
  right: 27px;
  height: 500px;
  width: 358px;
  border-radius: 10px;
  display: none;
}

.message-block-header{
  background: linear-gradient(92.71deg, #000033 -13.36%, #cccccc 101.39%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0px;
  padding-bottom: 17px;
}

.message-text{
  text-align: left;
  padding: 12px;
  font-size: 19px;
  margin-bottom: 0px;
}

.chat-design{
  width: 100%;
  position: absolute;
  left: 0px;
}

.chat-close{
  border-radius: 50%;
  padding: 13px;
  padding-bottom: 12px;
  padding-top: 12px;
  float: right;
  background: #1C345442;
  font-size: 17px;
}

.list-messages{
  margin-top: 25px;
  color: black;
  height: 359.5px;
}

.chat-list-messages{
  margin-top: 36px;
  color: black;
  height: 290.5px;
  overflow-x: none;
  overflow-y: scroll;
}
.message-view{
  display: flex;
  padding-left: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding-bottom: 20px;
  padding-top: 10px;
  padding-right: 10px;
  border: 2px solid white;
}
.message-logo{
  width: 10%;
  text-align: center;
  margin-right: 20px;
}
.message-font-icon{
  width: 100%;
}
.query-info{
  cursor: pointer;
}
.query-text{
  font-size: 16px;
}
.query-date{
  font-size: 10px;
  text-align: left;
}
.bottom-menu{
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.bottom-menu div{
  width: 50%;
}

.bottom-menu div a{
  text-decoration: none;
  font-size: 15px;
  color: black;
}

.bottom-menu div a:hover{
  background: linear-gradient(92.71deg, #002AC0 -13.36%, #07B5FF 101.39%);
  color: white;
  padding: 10px;
  border-radius: 15px;
}

.profile-block{
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
}

.back-icon{
  width: 10%;
  font-size: 20px;
  cursor: pointer;
}

.profile-image-block{
  width: 73%;
  display: flex;
  margin-left: 15px;
}

.profile-logo{
  width: 11%;
  border: 1px solid #eaebee;
  border-radius: 50%;
  padding: 5px;
  padding-right: 7px;
  padding-left: 7px;
}

.close-icon-block{
  width: 4%;
  cursor: pointer;
}

.username{
  font-size: 19px;
}

.userstatus{
  font-size: 13px;
}
.userinfo-block{
  margin-left: 9px;
}

.user-state-icon{
  color: green;
  font-size: 6px;
  margin-right: 3px;
}

.user-message{
  display: flex;
  margin-left: 15px;
  text-align: left;
  float: left;
  margin-top: 20px;
  font-family: Georgia, serif;
}

.chat-user-profile{
  width: 63%;
  border: 2px solid #dedee7;
  border-radius: 50%;
  padding: 5px;
  padding-right: 7px;
  padding-left: 7px;
}

.message-profile{
  width: 20%;
  margin-right: 6px;
}

.message-user-text{
  
  width: 67%;
}
.msg-text{
  font-size: 14px;
  background: #FFFFFF;
  padding: 11px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px 20px 20px 10px;
  font-family: Georgia, serif;
}

.message-time{
  font-size: 12px;
  color: #918c8c;
  padding-top: 8px;
  font-family: serif;
}

.system-message{
  background: #0E91EF;
  color: white;
  padding: 10px 15px 10px 15px;
  border-radius: 15px 4px 15px 15px;
  font-size: 15px;
  text-align: left;
  width: 67%;
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  font-family: Georgia, serif;
}

.text-input{
  border: 1px solid #c1c1c4;
  padding: 10px;
  width: 86%;
  margin-right: 13px;
  font-family: Georgia, serif;
}
.send-button{
  background: linear-gradient(106.71deg, #000033 -13.36%, #cccccc 101.39%);
  color: white;
  border: 1px solid;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-family: Georgia, serif;
}

.send-button-img{
  width: 73%;
}

.msg-input-block{
  display: flex;
  width: 100%;
  font-family: Georgia, serif;
}

.chat-bottom-menu{
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: Georgia, serif;
}

.font-color{
  color: #1C324F61;
  font-size: 15px;
  cursor: pointer;
  font-family: Georgia, serif;
}

.chat-icon-block{
  text-align: left;
  margin-top: 12px;
  font-size: 15px;
  color: #1C324F61;
  padding: 20px;
  font-family: Georgia, serif;
}

.chat-icon-block-span{
  margin-right: 20px;
  padding: 20px;
  font-family: Georgia, serif;
}

.powered-by{
  text-align: end;
  float: right;
  margin-right: 0px;
  color: #1B2B41B0;
  font-weight: 500;
  font-family: Georgia, serif;
}

.cursor{
  cursor: pointer;
}

.app-close-btn{
  background: #000033;
    border-radius: 50%;
    bottom: 8px;
    cursor: pointer;
    display: none;
    font-size: 27px;
    padding: 11px;
    position: absolute;
    padding-bottom: 8px;
    padding-top: 9px;
    right: 12px;
}

.wave-container {
  width: 100%;
  height: 165px;
  background-color: #f0f0f0; /* Change this to your desired background color */
  overflow: hidden;
  position: relative;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px; /* Adjust this value to control the height of the wave */
  background: #ffffff; /* Change this color as needed */

  /* Creating the wave shape using CSS border-radius */
  border-radius: 3% 3% 0 0;
}

::-webkit-scrollbar {
  width: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
